<template>
    <div class="activitys-info">
        <b-button class="mb-3 col-auto" variant="light" @click="$router.go(-1)">
            <i class="fas fa-chevron-left"></i> Back
        </b-button>
        <div v-if="activity" class="activitys-info__number">
            Operation {{ activity.id }}
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-7">
                <account-information v-if="account" :account="account" />
                <order-details v-if="order" :order="order" />
                <activity-information v-if="activity" :activity="activity" />
            </div>
            <div class="col-lg-5">
                <customer-information v-if="customer" :customer="customer" />
                <payment-method v-if="order" :card="card" />
                <device-information v-if="device" :device="device" />
                <threeds-information v-if="threeds && threeds.active" :threeds="threeds" />
            </div>
        </div>
    </div>
</template>

<script>
import AccountInformation from '@/components/activitys/AccountInformation.vue';
import CustomerInformation from '@/components/activitys/CustomerInformation.vue';
import PaymentMethod from '@/components/activitys/PaymentMethod.vue';
import OrderDetails from '@/components/activitys/OrderDetails.vue';
import DeviceInformation from '@/components/activitys/DeviceInformation.vue';
import ActivityInformation from '@/components/activitys/ActivityInformation.vue';
import ThreedsInformation from '@/components/activitys/ThreedsInformation.vue';

export default {
    components: {
        AccountInformation,
        CustomerInformation,
        PaymentMethod,
        OrderDetails,
        DeviceInformation,
        ActivityInformation,
        ThreedsInformation,
    },
    data() {
        return {
            activity: null,
            risk_notes: null,
            account: null,
            customer: null,
            card: null,
            order: null,
            device: null,
            threeds: null,
            activity_id: this.$route.query.id,
        };
    },
    mounted() {
        this.getActivity();
    },
    methods: {
        async getActivity() {
            try {
                const { data } = await this.$axios.get(`/_/activity/${this.activity_id}`);
                this.activity = data;
                this.account = data.account;
                this.risk_notes = data.risk_notes;
                this.customer = data.customer;
                this.card = data.card;
                this.order = data.order;
                this.device = data.device;
                this.threeds = data.threeds;
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>

<style lang="scss">
.activitys-info {
    &__number {
        font-size: 0.95rem;
        margin-bottom: 1rem;
    }
}
</style>

