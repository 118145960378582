<template>
    <ui-card v-if="device" class="device-information" title="Device information">
        <ui-list>
            <ui-list-item title="IP:" v-if="device.ip">
                <template #text>
                    {{ device.ip }}
                </template>
            </ui-list-item>
            <ui-list-item title="Fingerprint:" v-if="device.fingerprint">
                <template #text>
                    {{ device.fingerprint }}
                </template>
            </ui-list-item>
            <ui-list-item title="City:" v-if="device.city">
                <template #text>
                    {{ device.city }}
                </template>
            </ui-list-item>
            <ui-list-item title="Country:" v-if="device.country">
                <template #text>
                    {{ device.country }}
                </template>
            </ui-list-item>
            <ui-list-item title="Latitude:" v-if="device.latitude">
                <template #text>
                    {{ device.latitude }}
                </template>
            </ui-list-item>
            <ui-list-item title="Longitude:" v-if="device.longitude">
                <template #text>
                    {{ device.longitude }}
                </template>
            </ui-list-item>
            <ui-list-item title="Region:" v-if="device.region">
                <template #text>
                    {{ device.region }}
                </template>
            </ui-list-item>
            <ui-list-item title="Timezone:" v-if="device.timezone">
                <template #text>
                    {{ device.timezone }}
                </template>
            </ui-list-item>
        </ui-list>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';

export default {
    components: {
        UiCard,
        UiList,
        UiListItem,
    },
    props: {
        device: { 
            type: Object, 
            default: null 
        },
    },
}
</script>

<style lang="scss">
.device-information {
    &__list {
        display: flex;
        flex-direction: column;
    }

    &__list-item {
        display: flex;
        flex-wrap: wrap;
        padding: 12px 0px;

        @media (max-width: 540px) {
            display: initial;
        }
        
        &:not(:last-child) {
            border-bottom: 1px solid $ecart-secondary-100;
        }
    }

    &__list-title {
        color: $ecart-secondary-500;
        font-weight: 500;
        width: 150px;
        max-width: 100%;
    }
    
    &__list-text {
        font-weight: 500;
    }

    &__contact-buttons {
        display: flex;
        gap: 5px;
    }
}
</style>