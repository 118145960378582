<template>
    <ui-card v-if="customer" title="Customer Information">
        <ui-list>
            <ui-list-item title="Name:" :text="`${customer.first_name} ${customer.last_name || ''}`" />
            <ui-list-item title="Email:">
                <template #text>
                    <ui-link
                        v-if="customer.email"
                        weight="medium"
                        :link="`mailto:${customer.email}`"
                    >
                        {{ customer.email }}
                    </ui-link>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
            </ui-list-item>
            <ui-list-item title="Phone:">
                <template #text>
                    <ui-link
                        v-if="customer.phone"
                        weight="medium"
                        :link="`tel:+${customer.phone}`"
                    >
                        {{ customer.phone }}
                    </ui-link>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
            </ui-list-item>
            <ui-list-item title="Contact:">
                <template #text>
                    <contact-links :phone="customer.phone || null" :email="customer.email || null" />
                </template>
            </ui-list-item>
        </ui-list>
    </ui-card>
</template>

<script>
import ContactLinks from '@/components/ui/ContactLinks';
import UiBadge from '@/components/ui/Badge';
import UiCard from '@/components/ui/Card';
import UiLink from '@/components/ui/Link.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';

export default {
    components: {
        ContactLinks,
        UiBadge,
        UiCard,
        UiLink,
        UiList,
        UiListItem,
    },
    props: {
        customer: {
            type: Object,
            default: null,
        },
    },
};
</script>
