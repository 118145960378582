<template>
    <div class="ui-list" :class="listClasses">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'List',
    props: {
        bordered: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        listClasses() {
            const classes = [];

            if (this.bordered) {
                classes.push('ui-list--bordered');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-list {
    display: flex;
    flex-direction: column;

    &--bordered {
        border: 1px solid $ecart-secondary-100;
        border-radius: 8px;
    }
}
</style>
