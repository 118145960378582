<template>
    <div v-if="order" class="order-details">
        <ui-card>
            <div class="order-details__amount-container">
                <span class="order-details__amount-total">{{ _currency(order.totals.total, currency) }}</span>
                <hr />
                <div class="d-flex mt-3 flex-wrap align-items-center">
                    <ui-status class="me-auto" :status="order.status"></ui-status>
                    <p class="mb-0">Order Number: <br /> #{{ order.number }}</p>
                </div>
            </div>
            <p class="order-details__title">Order details</p>
            <ul class="order-details__list-group list-group">
                <li v-for="(i, index) in order.items" :key="index" class="order-details__list-group-item list-group-item">
                    <div class="order-details__list-group-icon">
                        <span class="fas fa-box"></span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="order-details__list-group-item-title text-capitalize">{{ i.name }}</span>
                        <small class="text-muted">Price: {{ _currency(i.price, currency) }}</small>
                        <small class="text-muted">Quantity: {{ i.quantity }}</small>
                    </div>
                    <div class="ms-auto">
                        <span class="order-details__list-group-item-total">{{ _currency(i.total, currency) }}</span>
                    </div>
                </li>
            </ul>
            <p class="order-details__title">Total</p>
            <ul class="order-details__list-group order-details__list-group--no-internal-border list-group">
                <li v-if="order.totals.subtotal != order.totals.total" class="order-details__list-group-item list-group-item">
                    <span class="order-details__list-group-item-title">Subtotal</span>
                    <span class="order-details__list-group-item-points"></span>
                    <span class="order-details__list-group-item-amount">{{ _currency(order.totals.subtotal, currency) }}</span>
                </li>
                <li v-if="order.totals.tax" class="order-details__list-group-item list-group-item">
                    <span class="order-details__list-group-item-title">Tax</span>
                    <span class="order-details__list-group-item-points"></span>
                    <span class="order-details__list-group-item-amount">{{ _currency(order.totals.tax, currency) }}</span>
                </li>
                <li v-if="order.totals.shipping" class="order-details__list-group-item list-group-item">
                    <span class="order-details__list-group-item-title">Shipping</span>
                    <span class="order-details__list-group-item-points"></span>
                    <span class="order-details__list-group-item-amount">{{ _currency(order.totals.shipping , currency) }}</span>
                </li>
                <li v-if="order.totals.discount" class="order-details__list-group-item list-group-item">
                    <span class="order-details__list-group-item-title">Discount</span>
                    <span class="order-details__list-group-item-points"></span>
                    <span class="order-details__list-group-item-amount">{{ _currency(order.totals.discount, currency) }}</span>
                </li>
                <li class="order-details__list-group-item list-group-item">
                    <span class="order-details__list-group-item-title">Total</span>
                    <span class="order-details__list-group-item-points"></span>
                    <span class="order-details__list-group-item-amount order-details__list-group-item-amount--success">{{ getTotal() }}</span>
                </li>
            </ul>
        </ui-card>
        <ui-card v-if="shipping" title="Shipping address">
            <ui-list>
                <ui-list-item title="Name:" :text="`${shipping.first_name} ${shipping.last_name || ''}`" />
                <ui-list-item title="Address:" :text="shipping.address1" />
                <ui-list-item v-if="shipping.address2" title="Address 2:" :text="shipping.address2" />
                <ui-list-item title="Postal code:" :text="shipping.postal_code" />
                <ui-list-item title="Country:" :text="`${shipping.country.name || ''} (${shipping.country.code || ''})`" />
                <ui-list-item title="State:" :text="`${shipping.state.name || ''} (${shipping.state.code || ''})`" />
                <ui-list-item title="City:" :text="shipping.city" />
                <ui-list-item title="Phone:" :text="shipping.phone" />
                <template v-if="shipping.metadata && Object.keys(shipping.metadata)">
                    <ui-list-item v-for="(v, k) in shipping.metadata" :key="k" :title="`${k.replace('_', ' ')}:`" :text="v" />
                </template>
                <ui-list-item v-if="shipping.reference" title="Reference:" :text="shipping.reference" />
            </ui-list>
        </ui-card>
    </div>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';

export default {
    components: {
        UiCard,
        UiList,
        UiListItem,
        UiStatus,
    },
    props: {
        order: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            currency: null,
            shipping: null,
        };
    },
    created() {
        try {
            this.currency = this.order.currency;
            this.shipping = this.order.shipping_address;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        getTotal() {
            let total;
            total = this.order.totals.total;
            return this._currency(total, this.currency);
        },
    },
};
</script>

<style lang="scss" scoped>
.order-details {
    &__title {
        font-weight: 500;
        margin-bottom: 0;
    }

    &__paid-at {
        color: $ecart-secondary-500;
    }

    &__amount-container {
        border: 1px solid $ecart-secondary-100;
        border-radius: 8px;
        padding: 10px;
        text-align: center;

        hr {
            background-color: $ecart-secondary-100;
            margin-bottom: 10px;
            margin-top: 10px;
            opacity: 1;
        }
    }

    &__amount-title {
        margin-bottom: 10px;
        font-weight: 500;
    }

    &__amount-total {
        color: $general-success;
        font-size: 1.71rem;
        font-weight: 600;
        word-break: break-all;

        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
    }

    &__list-group {
        border-radius: 8px;

        &--no-internal-border {
            li {
                &:not(:last-child) {
                    border-bottom: 0;
                }
            }
        }
    }

    &__list-group-item {
        align-items: center;
        background-color: transparent;
        border-color: $ecart-secondary-100;
        display: flex;
        gap: 16px;
    }

    &__list-group-icon {
        align-items: center;
        border: 1px solid $ecart-secondary-100;
        border-radius: 8px;
        color: $ecart-secondary-300;
        display: flex;
        font-size: 25px;
        height: 50px;
        justify-content: center;
        min-width: 50px;
        width: 50px;
    }

    &__list-group-item-title {
        font-weight: 500;
    }

    &__list-group-item-total {
        font-weight: 500;
    }

    &__list-group-item-amount {
        font-weight: 500;
        color: $ecart-secondary-500;

        &--danger {
            color: $general-error;
            font-size: 1.2rem;
            font-weight: 600;
        }
        
        &--success {
            color: $general-success;
            font-size: 1.2rem;
            font-weight: 600;
        }
    }

    &__list-group-item-points {
        flex: 1;
        border-bottom: 1px dashed $gray-300;
    }
}

.items {
    img {
        height: 50px;
        width: 50px;
        object-fit: cover;
        border-radius: $border-radius;
    }
}
.main-total {
    font-size: 26px;
    word-break: break-all;
    @include media-breakpoint-up(md) {
        font-size: 32px;
    }
}
.totals-details {
    margin-left: auto;
    min-width: 100%;
    @include media-breakpoint-up(md) {
        min-width: 80%;
    }
}
</style>
