<template>
    <ui-card v-if="activity">
        <div>
            <div v-if="activity.created_at" class="activity-details__creat-at ms-auto d-flex justify-content-end mb-3">
                {{ getActivityAtDate(activity.created_at) }}
            </div>
            <h5 class="ui-card__title">Activity Status</h5>
            <div>  
                <br/>
                <ui-list class="mb-2">
                    <ui-list-item v-if="activity.status" title="Status:">
                        <template #text>
                            <ui-status :status="activity.status"></ui-status>
                        </template>
                    </ui-list-item>
                    <ui-list-item v-if="activity.message" title="Status message:">
                        <template #text>
                            {{ activity.message }}
                        </template>
                    </ui-list-item>
                    <ui-list-item v-if="activity.risk_level" title="Risk level:">
                        <template #text>
                            <ui-risk-level
                                :risk_level="activity.risk_level"
                                :risk_score="activity.risk_score"
                            />
                        </template>
                    </ui-list-item>
                    <ui-list-item v-if="activity" title="Risk notes:"/>
                </ui-list>          
            </div>
            <div class="notes-container d-flex flex-column py-3">
                <list-group>
                    <div v-for="(risk_note , index) in activity.risk_notes" :key="index" class="fs-14px list-group-item">
                        <div class="d-flex w-100 justify-content-between py-2">
                            <p class="mb-1"><i class="far fa-sticky-note"></i> {{ risk_note.message }}</p>
                            <div class="d-block">
                                <small class="badge rounded-pill px-2 font-normal text-capitalize d-inline-block badge--success">{{ risk_note.score }} / {{ risk_note.count }}</small>
                            </div>
                        </div>
                    </div>
                </list-group>
            </div>
            <list-group-item v-if="!activity.risk_notes.length">
                <empty-state title="No risk notes found..."/>
            </list-group-item>
        </div>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';
import EmptyState from '@/components/ui/EmptyState';
import ListGroup from '@/components/ui/list-group/ListGroup';
import ListGroupItem from '@/components/ui/list-group/ListGroupItem';
import UiRiskLevel from '@/components/ui/BadgeRiskLevel';

export default {
    components: {
        UiCard,
        UiList,
        UiListItem,
        UiStatus,
        EmptyState,
        ListGroup,
        ListGroupItem,
        UiRiskLevel
    },
    props: {
        activity: { 
            type: Object, 
            default: null 
        }
    },
    methods: {
        getActivityAtDate(date) {
            if (!isNaN(date)) {
                return this.$dayjs(date * 1000).format('LLLL');
            }
            return this.$dayjs(date).format('LLLL');
        },
    }
}
</script>

<style lang="scss">
.activity-details {
    &__creat-at {
        color: $ecart-secondary-500;
    }
}
.notes-container {
    max-height: 20rem;
    overflow-y: auto;
}
.badge {
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    padding: 0px 4px;
    white-space: nowrap;

    &--success {
        background-color: rgba($general-success, 0.2);
        color: $general-success;
    }
}

</style>