<template>
    <ui-card title="Payment method">
        <ui-list v-if="card">
            <ui-list-item v-if="card.brand" title="brand:" :text="card.brand.toUpperCase()" />
            <ui-list-item title="Last 4 digits:" :text="getLast4(card)" />
            <ui-list-item title="Name:" :text="card.name" />
        </ui-list>
        <ui-list  v-else>
            <ui-list-item title="method:" class="text-capitalize" text="cash" />
        </ui-list>
    </ui-card>
</template>

<script>
import { get } from 'lodash';

import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';

export default {
    components: { UiCard, UiList, UiListItem },
    props: {
        card: {
            type: Object,
            default: null,
        }
    },
    methods: {
        getLast4(card) {
            return get(card, 'last4', 'Empty data');
        }
    }
};
</script>
